@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
    font-family: 'Noto Sans KR', sans-serif;
}
*{
    font-family: 'Noto Sans KR', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    scroll-behavior: smooth;
}

.ranking-active{
    text-align: center;
    border-bottom: 3px solid #00A0E9;
    color: #00A0E9;
}
.ranking-inactive{
    text-align: center;
    border-bottom: 3px solid rgba(170, 170, 170, 1);
    color: rgba(170, 170, 170, 1);
}
.animal-active{
    text-align: center;
    border-bottom: 3px solid #2C75FF;
    color: #2C75FF;
}
.animal-inactive{
    text-align: center;
    border-bottom: 3px solid rgba(170, 170, 170, 1);
    color: rgba(170, 170, 170, 1);
}
.medal-active{
    text-align: center;
    border-bottom: 3px solid #172A88;
    color: #172A88;
}
.medal-inactive{
    text-align: center;
    border-bottom: 3px solid rgba(170, 170, 170, 1);
    color: rgba(170, 170, 170, 1);
}
.mooa-active{
    text-align: center;
    border-bottom: 3px solid #172A88;
    color: #172A88;
}
.mooa-inactive{
    text-align: center;
    border-bottom: 3px solid rgba(170, 170, 170, 1);
    color: rgba(170, 170, 170, 1);
}
