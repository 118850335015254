@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr * { 
    font-family: 'Noto Sans KR', sans-serif;
}
*{
    font-family: 'Noto Sans KR', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    scroll-behavior: smooth;
}